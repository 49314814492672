/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


ion-toolbar {
  //--background: left 5% / 15% 100% repeat-x url("/assets/image/title_background_2.jpg");
  //color: gold;
  //
  //.header-label {
  //  max-width: 50vw !important;
  //  margin: 10px !important;
  //  vertical-align: -webkit-baseline-middle;
  //  display: inline-block;
  //  text-overflow: ellipsis;
  //  overflow: hidden;
  //  white-space: nowrap;
  //}
  //
  //@media only screen and (max-width: 400px) {
  //  .header-label {
  //    max-width: 40vw !important;
  //  }
  //}


  padding: 0 100px;
}

.active-element {
  cursor: pointer;
}

.menu-toggle {
  --background: 100% url("/assets/image/menu-background.png");
  ion-list {
    background: inherit;
    ion-item {
      color: gold;
      --background: #0a0809;
      cursor: pointer;
      ion-icon {
        color: gold;
      }
    }
  }
}


button.button-native {
  font-size: 16px
}

.ion-color-primary {
  //background: repeat-x url("/assets/image/home_background_7.jpg") !important;
  --ion-color-base: left 10% / 1000% 1000% repeat-x url("/assets/image/home_background_8.jpg") !important;
  //font-size: 16px
}

.ion-color-toast {
  //background: repeat-x url("/assets/image/home_background_7.jpg") !important;
  --ion-color-base: gold!important;
  font-size: 16px;
  color: black;
  //opacity: 0.8;
}

.custom-button_default {
  --ion-color-base: left 10% / 100% 100% no-repeat url("/assets/image/button_background_1.png") !important;
  --background: left 10% / 100% 100% no-repeat url("/assets/image/button_background_1.png") !important;
  --color-hover: gold;
}

.custom-button_disable {
  //--ion-color-base: left 10% / 100% 100% no-repeat url("/assets/image/button_background_2.png") !important;
  //--background: left 10% / 100% 100% no-repeat url("/assets/image/button_background_2.png") !important;
  //--color-hover: gold;

  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //gap: 12px;
  //
  //--background: #0020C6;
  //border-radius: 10px;

  background: #FFFFFF;
  --background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 8px;
  --border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  height: 64px;
  width: 280px;
  margin-right: 32px;
}

.custom-button_primary {
  //--ion-color-base: left 10% / 100% 100% no-repeat url("/assets/image/button_background_3.png") !important;
  //--background: left 10% / 100% 100% no-repeat url("/assets/image/button_background_3.png") !important;
  //--color-hover: gold;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 16px 32px; */
  gap: 12px;
  background: rgba(0, 32, 198, 0.5);
  --background: rgba(0, 32, 198, 0.5);
  border-radius: 10px;
  --border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  width: 280px;
  height: 64px;
}

.custom-button_dark {
  //--ion-color-base: left 10% / 100% 100% no-repeat url("/assets/image/button_background_4.png") !important;
  //--background: left 10% / 100% 100% no-repeat url("/assets/image/button_background_4.png") !important;
  //--color-hover: gold;

  background: #000000;
  --background: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  --border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  height: 64px;
  width: 280px;
  margin-right: 32px;
}

.custom-button_light {
  //--ion-color-base: left 10% / 100% 100% no-repeat url("/assets/image/button_background_5.png") !important;
  //--background: left 10% / 100% 100% no-repeat url("/assets/image/button_background_5.png") !important;
  //--color-hover: gold;

  box-sizing: border-box;

  --background: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 8px;

  height: 40px;
  width: 180px;
}

.custom-button_white {
  //--ion-color-base: left 10% / 100% 100% no-repeat url("/assets/image/button_background_6.png") !important;
  //--background: left 10% / 100% 100% no-repeat url("/assets/image/button_background_6.png") !important;
  //--color-hover: gold;

  --background: #FFFFFF;
  border: 3px solid #000000;
  border-radius: 10px;
  --font-family: "Montserrat";
  --font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  height: 40px;
  width: 180px;
  margin-right: 32px;
}

.custom-button_danger {
  //--ion-color-base: left 10% / 100% 100% no-repeat url("/assets/image/button_background_7.png") !important;
  //--background: left 10% / 100% 100% no-repeat url("/assets/image/button_background_7.png") !important;
  //--color-hover: gold;

  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  --background: #FF7929;
  border-radius: 10px;
  float: right;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.move-fab-icon {
  color: #cceaf5;
}

.header-button {
  float: right;
}

//::ng-deep exam-sign-form.ion-page {
//  --width: 600px;
//  --height: 215px;
//}
//
//.modal-wrapper {
//  transform: translate3d(0px, 40px, 0px);
//  opacity: 0.01;
//  width: 600px;
//  height: 215px;
//}

ion-modal.my-custom-modal-css::part(content) {
  --height: 215px;
  position: absolute;
  top: 30%;
  --width: 600px;
  width: 600px;
  height: 215px;
  display: block;
}

.back-header-button {
  --background: gold;
  --color: #131313;
  --padding-start: 3px;
  --padding-end: 3px;
  --border-radius: 5px;
  max-height: 44px;
}

ion-icon {
  &[class*="custom-"] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    width: 1em;
    height: 1em;
  }

  &[class*="custom-donation"] {
    mask-image: url('./assets/svg/donation.svg');
  }

}

.custom-toast-style {
  //--background: #b4ab7c;
  --background: gold;
  --color: black;
  //--button-color: black;
  opacity: 0.8;
  font-size: 16px;

  //button {
  //  --color: black;
  //  color: black;
  //}
  //
  //.toast-wrapper.toast-container.toast-button-group.toast-button {
  //  --color: black;
  //  color: black;
  //}
  //
  //.toast-button-cancel {
  //  color: black!important;
  //}
  //
  //.toast-button{
  //  color: red;
  //}
}

.login_page-inputs {
  /* Rectangle 18907 */
  position: absolute;
  //left: 58.33%;
  //right: 15%;
  //top: 24.4%;
  //bottom: 67.98%;

  background: #FFFFFF;
  border-radius: 10px;

  width: 70%;
}

.base-blue-button {
  background: inherit;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 32px;
  gap: 12px;

  border: 3px solid #0020C6;
  border-radius: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;

  color: #000000;
}
